import { ConferenceEdition, ConferenceExhibitorListItem } from '@gain/rpc/cms-model'
import { listFilter } from '@gain/rpc/utils'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Link from '@mui/material/Link'
import React, { useCallback, useRef } from 'react'
import { Route, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'

import { useInputFormData } from '../../../common/input-fields'
import SwrDataGrid, { SwrDataGridRef } from '../../../common/swr-data-grid'
import { ItemPageContent } from '../../../layout/item-page'
import { ASSET_KEY_FACTS_PATH } from '../../asset'
import { CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH } from '../../conference-edition'
import ConferenceEditionExhibitorRoute from './exhibitor'

export default function ConferenceEditionExhibitorsRoute() {
  const data = useInputFormData<ConferenceEdition>()
  const params = useParams<{ id: string }>()

  const dataGridRef = useRef<SwrDataGridRef>(null)
  const exhibitorsPath = generatePath(CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH, { id: params.id })

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <ItemPageContent variant={'grid'}>
      <Card>
        <SwrDataGrid
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-person'}
              disabled={data?.live}
              href={`${exhibitorsPath}/new`}
              variant={'contained'}>
              Add exhibitor
            </Button>,
          ]}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              filterable: false,
              minWidth: 125,
              flex: 1,
            },
            {
              field: 'linkedAssetId',
              headerName: 'Asset',
              flex: 1,
              renderCell: ({ row }) =>
                row.linkedAssetId ? (
                  <Link
                    href={generatePath(ASSET_KEY_FACTS_PATH, {
                      id: row.linkedAssetId,
                    })}>
                    {row.linkedAssetName}
                  </Link>
                ) : (
                  '-'
                ),
            },
          ]}
          filter={[
            listFilter<ConferenceExhibitorListItem>(
              'conferenceEditionId',
              '=',
              parseInt(params.id, 10)
            ),
          ]}
          label={'Exhibitors'}
          method={'data.listConferenceExhibitors'}
          path={':id'}
          sort={[]}
          disableColumnFilter
          disableSearch
        />
      </Card>

      <Route path={`${CONFERENCE_EDITION_EXHIBITORS_PAGE_PATH}/:exhibitorId`}>
        <ConferenceEditionExhibitorRoute onCrudAction={handleRefreshGrid} />
      </Route>
    </ItemPageContent>
  )
}
