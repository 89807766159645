import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { isEmpty, isObject, setWith } from 'lodash'
import { FieldPath, FieldValues, useFormContext } from 'react-hook-form'

import { useFieldName, useInputFormContext } from './input-form-hooks'

export interface InputFieldClearButtonProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  name: TName
}

const StyledClearIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(),
}))

function isObjectEmpty(obj: object): boolean {
  return !Object.values(obj).some(Boolean)
}

/**
 * Renders button that will clear the field that is provided
 */
export default function InputFieldClearButton<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ name }: InputFieldClearButtonProps<TFieldValues, TName>) {
  const inputForm = useInputFormContext()
  const fieldName = useFieldName<TName>(name)
  const form = useFormContext()

  const fieldValue = form.watch(fieldName)

  return (
    <Stack justifyContent={'center'}>
      <StyledClearIconButton
        disabled={
          isEmpty(fieldValue) ||
          (isObject(fieldValue) && isObjectEmpty(fieldValue)) ||
          inputForm.disabled
        }
        onClick={() => {
          inputForm.patch(setWith({}, fieldName, null, Object))
        }}
        size={'small'}>
        <CloseIcon />
      </StyledClearIconButton>
    </Stack>
  )
}
