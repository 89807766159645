import React from 'react'
import Rate from 'rc-rate'
import styles from './Rating.module.scss'
import Icon, { ICON_STAR } from '../Icons/Icon'
import KeyboardInput from '../FormElements/KeyboardInput/KeyboardInput'

const Rating = (props) => (
  <KeyboardInput>
    <Rate
      style={{ fontSize: 30 }}
      character={<Icon src={ICON_STAR} />}
      className={styles.rate}
      {...props}
    />
  </KeyboardInput>
)

export default React.memo(Rating)
