import { DESCRIPTION, MARKET, RATING, SEGMENTATION } from '../routes/asset/asset-route-utils'
import { ERROR_TYPE_WARNING } from './hasErrors'

export const addFieldValidations = ({ response }) => {
  const validations = []

  response.forEach((validation) => {
    if (validation.path === DESCRIPTION) {
      validations.push({
        path: 'description.short',
        message: 'Business activities should not be empty',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'description.history',
        message: 'Ownership history should not be empty',
        type: ERROR_TYPE_WARNING,
      })
    } else if (validation.path === MARKET) {
      validations.push({
        path: 'market.cagr',
        message: 'CAGR is greater than 30%',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'market.cagrStart',
        message: 'CAGR start year is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'market.cagrEnd',
        message: 'CAGR end year is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'market.size',
        message: 'Size and growth is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'market.competition',
        message: 'Competitive position is missing',
        type: ERROR_TYPE_WARNING,
      })
    } else if (validation.path === SEGMENTATION) {
      validations.push({
        path: 'segmentation.segments',
        message: 'The segmentation table is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'segmentation.title',
        message: 'Segmentation title is empty',
        type: ERROR_TYPE_WARNING,
      })
    } else if (validation.path === RATING) {
      validations.push({
        path: 'rating.growth',
        message: 'Revenue overall rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.organicGrowth',
        message: 'Revenue organic rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.nonCyclical',
        message: 'Non-cyclical rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.contracted',
        message: 'Revenue visibility is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.grossMargin',
        message: 'Gross margin rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.ebitda',
        message: 'EBITDA rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.conversion',
        message: 'Cash conversion rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.leader',
        message: 'Clear market leader rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.buyAndBuild',
        message: 'Buy-and-build rating is missing',
        type: ERROR_TYPE_WARNING,
      })
      validations.push({
        path: 'rating.multinational',
        message: 'Multinational rating is missing',
        type: ERROR_TYPE_WARNING,
      })
    } else {
      validations.push(validation)
    }
  })

  return validations
}
