import { get } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import PageContent from '../../../Components/Layout/PageContent'
import SourcesButton from '../../../Compositions/Buttons/SourcesButton'
import { FormLabelComposition } from '../../../Compositions/FormLabel/FormLabelComposition'
import SegmentsForm from '../../../Compositions/IndustryDetail/SegmentsForm'
import InputGroupComposition from '../../../Compositions/InputGroup/InputGroupComposition'
import InputTextArea from '../../../Compositions/Inputs/InputTextArea'
import AddArrayItemComposition, {
  dataSize,
} from '../../../Compositions/ItemActions/AddArrayItemComposition'
import { UpdateForm } from '../../../Compositions/UpdateForm/UpdateForm'
import { USED_FOR_INDUSTRY } from '../../../util/usedFor'
import { SEGMENTS } from '../../../routes/asset/asset-route-utils'
import { MARKET } from './helpers/industryDetailNames'

const mapStateToProps = (state) => ({
  data: state.fetchItem.data,
})

const IndustryDetailMarketPage = ({ data }) => (
  <PageContent>
    {data?.market?.definition && (
      <>
        <FormLabelComposition>Market definition</FormLabelComposition>
        <InputGroupComposition>
          <InputGroupItem>
            <UpdateForm initialValues={{ [MARKET]: data[MARKET] }}>
              {() => (
                <InputTextArea
                  path={`${MARKET}.definition`}
                  maxLength={2000}
                  title="Enter text"
                />
              )}
            </UpdateForm>
          </InputGroupItem>
        </InputGroupComposition>
      </>
    )}

    {data?.market?.structure && (
      <>
        <FormLabelComposition>Market structure</FormLabelComposition>
        <InputGroupComposition>
          <InputGroupItem>
            <UpdateForm initialValues={{ [MARKET]: data[MARKET] }}>
              {() => (
                <InputTextArea
                  path={`${MARKET}.structure`}
                  maxLength={2000}
                  title="Enter text"
                />
              )}
            </UpdateForm>
          </InputGroupItem>
        </InputGroupComposition>
      </>
    )}

    <FormLabelComposition>Market segments</FormLabelComposition>
    <InputGroupComposition>
      <UpdateForm
        initialValues={{
          [MARKET]: {
            [SEGMENTS]: get(data, [MARKET, SEGMENTS]),
          },
        }}>
        {() => (
          <>
            <SegmentsForm path={`${MARKET}.${SEGMENTS}`} />
            <AddArrayItemComposition
              path={`${MARKET}.${SEGMENTS}`}
              value={{
                order: dataSize(get(data, [MARKET, SEGMENTS])),
              }}>
              Add segmentation
            </AddArrayItemComposition>
          </>
        )}
      </UpdateForm>
    </InputGroupComposition>
    <FormLabelComposition>ESG assessment</FormLabelComposition>
    <InputGroupComposition>
      <InputGroupItem>
        <UpdateForm initialValues={{ [MARKET]: data[MARKET] }}>
          {() => (
            <InputTextArea
              path={`${MARKET}.esg`}
              title="Enter text"
              rows={8}
            />
          )}
        </UpdateForm>
      </InputGroupItem>
    </InputGroupComposition>

    <SourcesButton
      usedFor={USED_FOR_INDUSTRY}
      initialValues={{ market: true }}
    />
  </PageContent>
)

IndustryDetailMarketPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default connect(mapStateToProps)(IndustryDetailMarketPage)
