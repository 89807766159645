import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import formatString from 'format-string-by-pattern'
import { noop } from 'lodash'
import { partialAdd } from '../../../util/partial'
import ModalBox from '../../../Components/Modal/ModalBox'
import ModalBoxHead from '../../../Components/Modal/ModalBoxHead'
import ModalBoxBody from '../../../Components/Modal/ModalBoxBody'
import InputGroupItem from '../../../Components/FormElements/InputGroup/input-group-item'
import FormLabel, {
  FORMLABEL_SECONDARY,
} from '../../../Components/FormElements/FormLabel/FormLabel'
import ModalBoxFoot from '../../../Components/Modal/ModalBoxFoot'
import { FormButton } from '../../../Components/Buttons/FormButton/FormButton'
import { BUTTON_STYLENAME_PILL } from '../../../Components/Buttons/Button'
import { SOURCES } from '../../../routes/asset/asset-route-utils'
import CreateInputField from './helpers/CreateInputField'
import { DATE_YYYY_MM } from '../../../util/dateFormat'
import CheckRadio from '../../../Components/FormElements/CheckRadio/CheckRadio'
import KeyboardInput from '../../../Components/FormElements/KeyboardInput/KeyboardInput'
import SpacedItems from '../../../Components/SpacedItems/SpacedItems'
import { nextResponse } from '../../../redux/action/nextAction'
import { addedSourcesModal } from '../../../redux/action/addedSourcesModal'
import CreateCreatableInputSelect from './helpers/CreateCreatableInputSelect'
import sourceOptions from '../../../json/sources.json'

const SourcesAddModal = ({ update, initialValues, usedFor }) => (
  <Form
    initialValues={initialValues}
    onSubmit={(values) => {
      const yearMonth = values.publicationYear ? values.publicationYear.split('/') : undefined

      return update(
        partialAdd(SOURCES, {
          ...values,
          order: 99,
          publicationMonth:
            yearMonth && yearMonth.length === 2 ? parseInt(yearMonth[1], 10) : undefined,
          publicationYear: yearMonth && parseInt(yearMonth[0], 10),
        }),
        nextResponse(addedSourcesModal)
      )
    }}>
    {({ handleSubmit, pristine, submitting, invalid, dirtySinceLastSubmit }) => (
      <form onSubmit={handleSubmit}>
        <ModalBox>
          <ModalBoxHead>New source</ModalBoxHead>
          <ModalBoxBody>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Title</FormLabel>
              <CreateInputField
                name="title"
                placeholder="Enter title"
                autoFocus
              />
            </InputGroupItem>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Source</FormLabel>
              <CreateCreatableInputSelect
                name="publisher"
                placeholder="source"
                options={sourceOptions}
              />
            </InputGroupItem>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>URL</FormLabel>
              <CreateInputField
                name="url"
                placeholder="Enter url"
                validate={noop}
              />
            </InputGroupItem>
            <InputGroupItem>
              <FormLabel styleName={FORMLABEL_SECONDARY}>Publication date</FormLabel>
              <CreateInputField
                name="publicationYear"
                placeholder={DATE_YYYY_MM}
                parse={formatString(DATE_YYYY_MM)}
                validate={noop}
              />
            </InputGroupItem>
            {usedFor.length > 0 && (
              <InputGroupItem>
                <FormLabel styleName={FORMLABEL_SECONDARY}>Used for</FormLabel>
                <SpacedItems>
                  {usedFor.map((uses) => (
                    <KeyboardInput
                      key={uses.name}
                      style={{
                        width: 'auto',
                      }}>
                      <Field
                        name={uses.name}
                        component="input"
                        type="checkbox">
                        {({ input }) => (
                          <CheckRadio
                            input={input}
                            label={uses.abbr}
                          />
                        )}
                      </Field>
                    </KeyboardInput>
                  ))}
                </SpacedItems>
              </InputGroupItem>
            )}
          </ModalBoxBody>
          <ModalBoxFoot>
            <FormButton
              styleName={BUTTON_STYLENAME_PILL}
              spinner={submitting}
              disabled={!dirtySinceLastSubmit && (submitting || pristine || invalid)}>
              Add source
            </FormButton>
          </ModalBoxFoot>
        </ModalBox>
      </form>
    )}
  </Form>
)

SourcesAddModal.propTypes = {
  update: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  usedFor: PropTypes.array.isRequired,
}

SourcesAddModal.defaultProps = {
  initialValues: undefined,
}

export default SourcesAddModal
