import { LinkedInIcon } from '@gain/components/icons'
import { PersonListItem } from '@gain/rpc/cms-model'
import { fixUrl } from '@gain/utils/common'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import React, { useCallback, useRef } from 'react'
import { Route } from 'react-router'

import SwrDataGrid, { SwrDataGridRef } from '../../common/swr-data-grid'
import LayoutDataGrid from '../../layout/data-grid'
import RoutePerson from './route-person'

export default function PeopleRoute() {
  const dataGridRef = useRef<SwrDataGridRef>(null)

  const handleRefreshGrid = useCallback(() => {
    dataGridRef.current?.refresh()
  }, [])

  return (
    <LayoutDataGrid title={'People'}>
      <Card>
        <SwrDataGrid
          ref={dataGridRef}
          actions={[
            <Button
              key={'new-person'}
              href={'/people/new'}
              variant={'contained'}>
              New
            </Button>,
          ]}
          columns={[
            {
              field: 'firstName',
              headerName: 'First Name',
              flex: 1,
              filterable: false,
            },
            {
              field: 'lastName',
              headerName: 'Last Name',
              flex: 1,
              filterable: false,
            },
            {
              field: 'linkedInUrl',
              headerName: 'LinkedIn',
              flex: 1,
              filterable: false,
              maxWidth: 100,
              renderCell: ({ value }: GridRenderCellParams<PersonListItem>) =>
                value ? (
                  <IconButton
                    href={fixUrl(value)}
                    rel={'noopener noreferrer'}
                    size={'small'}
                    sx={{ fontSize: 16 }}
                    target={'_blank'}>
                    <LinkedInIcon fontSize={'inherit'} />
                  </IconButton>
                ) : null,
            },
            {
              field: 'birthYear',
              headerName: 'Age',
              valueFormatter: (value) =>
                value ? new Date().getFullYear() - parseInt(value, 10) : '-',
              flex: 1,
              filterable: false,
            },
            {
              field: 'investorName',
              headerName: 'Investor',
              flex: 1,
              filterable: false,
            },
            {
              field: 'assets',
              headerName: 'Companies',
              flex: 1,
              filterable: false,
              sortable: false,
              valueFormatter: (value: PersonListItem['assets']) => (value ? value.length : '-'),
            },
          ]}
          label={'People'}
          method={'data.listPersons'}
          path={':id'}
          sort={[{ field: 'firstName', direction: 'asc' }]}
          disableColumnFilter
          useFullHeight
        />
      </Card>

      <Route path={'/people/:personId'}>
        <RoutePerson onCrudAction={handleRefreshGrid} />
      </Route>
    </LayoutDataGrid>
  )
}
