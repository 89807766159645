import PropTypes from 'prop-types'
import React, { useCallback, useContext, useState } from 'react'
import { useField } from 'react-final-form'
import Rating from '../../Components/Rating/Rating'
import FlexItem from '../../Components/Tabular/FlexTable/FlexItem'
import MethodContext from '../../Context/method.context'
import { partial } from '../../util/partial'

const InputRating = ({ path, allowHalf, options }) => {
  const [label, setLabel] = useState(null)
  const handleHover = useCallback(
    (value) => {
      if (!options) return
      const option = options.find((o) => o.value === value)
      if (option) setLabel(option.label)
      else setLabel(null)
    },
    [setLabel, options]
  )
  const { input } = useField(path, { type: 'number' })
  const { update } = useContext(MethodContext)
  const defaultLabel = options.find(
    (option) => option.value === (input.value === '' ? 0 : input.value)
  )

  return (
    <>
      <FlexItem style={{ flex: '0 0 238px' }}>
        <Rating
          value={input.value === '' ? 0 : input.value}
          allowHalf={allowHalf}
          onChange={(value) => update(partial(path, value === 0 ? null : value))}
          onHoverChange={handleHover}
        />
      </FlexItem>

      <FlexItem>{label || defaultLabel?.label}</FlexItem>
    </>
  )
}

InputRating.propTypes = {
  path: PropTypes.string.isRequired,
  allowHalf: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

InputRating.defaultProps = {
  allowHalf: false,
}

export default InputRating
