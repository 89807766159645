import { ASSETS_PAGE_PATH } from '../assets'

export enum AssetRouteTabs {
  KeyFacts = 'key-facts',
  Assessment = 'assessment',
  Background = 'background',
  Business = 'business',
  Financials = 'financials',
  Market = 'market',
  Sources = 'sources',
  TimeEntries = 'time-entries',
}

const ASSET_PAGE_PATH = `${ASSETS_PAGE_PATH}/:id`
export const ASSET_TABS_PAGE_PATH = `${ASSET_PAGE_PATH}/:tab?`
export const ASSET_KEY_FACTS_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.KeyFacts}`
export const ASSET_ASSESSMENT_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Assessment}`
export const ASSET_BACKGROUND_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Background}`
export const ASSET_BUSINESS_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Business}`
export const ASSET_FINANCIALS_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Financials}`
export const ASSET_MARKET_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Market}`
export const ASSET_SOURCES_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.Sources}`
export const ASSET_TIME_ENTRIES_PATH = `${ASSET_PAGE_PATH}/${AssetRouteTabs.TimeEntries}`

export const ASSET_PREVIEW_PATH = '/app/asset/:id/:name'
