import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'

import { ItemPageBlockTitle, ItemPageBlockTitleProps } from './item-page-block-title'

export type ItemPageBlockProps = Partial<ItemPageBlockTitleProps>

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  gap: theme.spacing(2),
}))

export function ItemPageBlock({ label, children, path }: PropsWithChildren<ItemPageBlockProps>) {
  return (
    <div>
      {label && (
        <ItemPageBlockTitle
          label={label}
          path={path}
        />
      )}
      <StyledPaper variant={'outlined'}>{children}</StyledPaper>
    </div>
  )
}
