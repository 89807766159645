import Flag from '@gain/components/flag'
import React from 'react'

import ArrowLink from '../../common/arrow-link'
import PageContentList from '../../Components/Layout/PageContentList'
import StickyTableHeader from '../../Components/Tabular/StickyTableHeader/StickyTableHeader'
import Tabular, { TABULAR_HEADER } from '../../Components/Tabular/Tabular'
import TabularCell, {
  CELL_TYPE_ACTIONS,
  CELL_TYPE_CLICK,
  CELL_TYPE_LARGE,
  CELL_TYPE_REGION,
  CELL_TYPE_SMALL,
  CELL_TYPE_XLARGE,
} from '../../Components/Tabular/TabularCell/TabularCell'
import TabularRow, {
  ROWTYPE_HEAD,
  ROWTYPE_HOVER,
} from '../../Components/Tabular/TabularRow/TabularRow'
import { SIZE_CAPTION_1 } from '../../Components/Text/Text'
import CompetitorActions from '../../Compositions/Actions/CompetitorActions'
import ActionsMenu from '../../Compositions/ActionsMenu/ActionsMenu'
import AddButton from '../../Compositions/Buttons/AddButton'
import { TabularCellSortLink } from '../../Compositions/Link/TabularCellSortLink'
import CompetitorCreateModal from '../../Compositions/Modals/CreateModals/competitor-create-modal.component'
import PageHeaderComposition from '../../Compositions/PageHeader/page-header-composition'
import { MethodContextProvider } from '../../Context/method-context.provider'
import { ASSET_KEY_FACTS_PATH } from '../../routes/asset'
import { COLORNAME_TEXT_PRIMARY } from '../../util/colors'
import { useCurrencySymbol } from '../../util/currency'
import { COMPETITOR_METHODS } from '../../util/methods'
import { nmbr } from '../../util/niceNumber'
import { COMPETITOR_PROFILE_PATH } from '../../util/path'
import { pathGenerate } from '../../util/pathGenerate'
import { toastCreate } from '../../util/toastCreate'
import { ListContainer } from './helpers/ListContainer'

export default function CompetitorsPage() {
  const currencySymbolCallback = useCurrencySymbol()

  return (
    <>
      <PageHeaderComposition
        extras={<AddButton onClick={toastCreate(<CompetitorCreateModal />)} />}
        title={'Competitors'}
      />
      <PageContentList>
        <StickyTableHeader>
          <Tabular styleName={TABULAR_HEADER}>
            <TabularRow styleName={ROWTYPE_HEAD}>
              <TabularCellSortLink
                sort={'-name'}
                styleName={CELL_TYPE_XLARGE}>
                Name
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-headquarters'}
                styleName={CELL_TYPE_REGION}>
                HQ
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-revenue'}
                styleName={CELL_TYPE_SMALL}>
                Revenue
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-revenueYear'}
                styleName={CELL_TYPE_SMALL}>
                Year
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-linkedAssetName'}
                styleName={CELL_TYPE_LARGE}>
                Linked company
              </TabularCellSortLink>
              <TabularCellSortLink
                sort={'-usedByAssets'}
                styleName={CELL_TYPE_SMALL}>
                Used by companies
              </TabularCellSortLink>
              <TabularCell styleName={CELL_TYPE_ACTIONS} />
            </TabularRow>
          </Tabular>
        </StickyTableHeader>
        <Tabular>
          <ListContainer>
            {(data) =>
              data.map((item) => (
                <MethodContextProvider
                  key={item.id}
                  data={item}
                  methods={COMPETITOR_METHODS}>
                  <TabularRow styleName={ROWTYPE_HOVER}>
                    <TabularCell styleName={CELL_TYPE_CLICK}>
                      <TabularRow
                        to={pathGenerate(COMPETITOR_PROFILE_PATH, {
                          id: item.id,
                        })}>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_XLARGE}>
                          {item.name}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_REGION}>
                          <Flag code={item.headquarters} />
                        </TabularCell>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          styleName={CELL_TYPE_SMALL}>
                          {nmbr(item.revenue, 'm', currencySymbolCallback(item.currency))}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>{item.revenueYear}</TabularCell>
                        <TabularCell
                          colorName={COLORNAME_TEXT_PRIMARY}
                          size={SIZE_CAPTION_1}
                          styleName={CELL_TYPE_LARGE}>
                          {item.linkedAssetId && (
                            <ArrowLink
                              to={pathGenerate(ASSET_KEY_FACTS_PATH, {
                                id: item.linkedAssetId,
                              })}>
                              {item.linkedAssetName}
                            </ArrowLink>
                          )}
                        </TabularCell>
                        <TabularCell styleName={CELL_TYPE_SMALL}>{item.usedByAssets}</TabularCell>
                      </TabularRow>
                    </TabularCell>
                    <TabularCell styleName={CELL_TYPE_ACTIONS}>
                      <ActionsMenu
                        content={
                          <CompetitorActions
                            data={item}
                            listPage
                          />
                        }
                        openerColorStyle={'darken'}
                      />
                    </TabularCell>
                  </TabularRow>
                </MethodContextProvider>
              ))
            }
          </ListContainer>
        </Tabular>
      </PageContentList>
    </>
  )
}
